<template>
    <header
        class="uro-navigation"
        :class="{
            'uro-navigation--center-logo': featureFlag('nav-logo-centered-on-home') && route.name === 'index',
            'uro-navigation--enhanced-search': featureFlag('nav-enhanced-search'),
        }"
        :data-sticky="scrollTop > 0"
    >
        <div class="uro-navigation__container">
            <NuxtLink
                class="uro-navigation__logo"
                aria-label="Go to home"
                to="/"
                @click="menuToggled = false"
            >
                <img
                    :alt="siteName"
                    :src="navLogoUrl"
                >
            </NuxtLink>

            <BaseLangSwitch
                v-if="featureFlag('lang-switch')"
            />

            <nav
                v-if="!isPending && navigationLinks?.length > 0 && featureFlag('header-full-navigation')"
                class="uro-navigation__menu"
                :data-toggled="menuToggled"
            >
                <template v-for="(item, index) in navigationLinks">
                    <template v-if="itemHasSubmenu(item)">
                        <TheNavigationLink
                            :key="index"
                            :title="menuTitles[index]"
                            :submenu="{ primaryMenu: item.primaryMenu, secondaryMenu: item.secondaryMenu }"
                            @close-menu="menuToggled = false"
                        />
                    </template>
                    <template v-else>
                        <TheNavigationLink
                            :key="index"
                            :title="menuTitles[index]"
                            :to="getItemLink(item)"
                            :href="getItemLink(item)"
                            @click="menuToggled = false"
                        />
                    </template>
                </template>

                <ClientOnly>
                    <footer class="uro-navigation__menu__footer">
                        <template v-if="status !== 'authenticated'">
                            <BaseButton
                                v-if="!disableLogin && featureFlag('login')"
                                target="_self"
                                element="a"
                                class="dn-button--label"
                                @click="() => {
                                    menuToggled = false;
                                    signIn('uroweb');
                                }"
                            >
                                Log in
                            </BaseButton>
                            <BaseButton
                                v-if="customNavigationButton"
                                :element="NuxtLink"
                                :to="customNavigationButton.link"
                                class="base-button"
                                @click="menuToggled = false"
                            >
                                {{ customNavigationButton.text }}
                            </BaseButton>
                        </template>
                        <template v-else-if="featureFlag('login')">
                            <BaseButton
                                to="/profile"
                                :title="`You are logged in ${user?.name ? `as ${user.name}` : ''}`"
                                class="uro-navigation__user"
                                aria-label="Go to your profile"
                                @click="menuToggled = false"
                            >
                                <BaseIcon icon="person" />
                                {{ user?.firstName }}
                            </BaseButton>
                        </template>
                        <BaseSocials />
                    </footer>
                </ClientOnly>
            </nav>

            <div class="uro-navigation__actions">
                <TheSearchForm
                    v-if="showBasicSearch"
                    :placeholder="t('search')"
                    :in-navigation="true"
                />
                <TheSearchFormEnhanced
                    v-else-if="showEnhancedSearch"
                    :placeholder="t('search')"
                    :in-navigation="true"
                />

                <ClientOnly>
                    <div
                        v-if="status !== 'authenticated'"
                        class="uro-navigation__login"
                    >
                        <a
                            v-if="!disableLogin && featureFlag('login')"
                            target="_self"
                            class="navigation-link"
                            @click="() => {
                                menuToggled = false;
                                signIn('uroweb');
                            }"
                        >
                            Log in
                        </a>

                        <BaseButton
                            v-if="customNavigationButton"
                            :element="NuxtLink"
                            :to="customNavigationButton.link"
                            class="base-button"
                            @click="menuToggled = false"
                        >
                            {{ customNavigationButton.text }}
                        </BaseButton>
                    </div>
                    <NuxtLink
                        v-else
                        to="/profile"
                        :title="`You are logged in ${user?.name ? `as ${user.name}` : ''}`"
                        class="uro-navigation__user"
                        :placeholder="t('search', 'site')"
                        aria-label="Go to your profile"
                        @click="menuToggled = false"
                    >
                        <BaseIcon icon="person" />
                    </NuxtLink>
                </ClientOnly>

                <TheHamburgerMenu
                    v-if="navigationLinks && navigationLinks?.length && featureFlag('header-full-navigation')"
                    :data-toggled="menuToggled"
                    @toggle-menu="menuToggled = !menuToggled"
                />
            </div>
        </div>
    </header>
</template>

<script setup>
import {useMultisite} from '~/composables/useMultisite';
import MainNavigationQuery from '~/graphql/queries/global/MainNavigation.graphql';
import { computed } from 'vue';

const t = useTranslation();

defineProps ({
    disableLogin: {
        type: Boolean,
        required: false,
        default: false
    }
});

const { featureFlag } = useMultisite();
const navLogoUrl = inject('navLogoUrl');
const siteName = inject('siteName');

const { data: { user }, status, signIn } = useAuth();
const { uriFromEntryFields } = useUri();

const NuxtLink = resolveComponent('NuxtLink');

const { variables } = useCraftVariables();

const { data, isPending } = await useLazyAsyncQuery({
    query: MainNavigationQuery,
    variables
});

const showBasicSearch = computed(() => {
    return (!(featureFlag('nav-hide-search-on-home') && route.path === '/') && !featureFlag('nav-enhanced-search'));
});

const showEnhancedSearch = computed(() => {
    return (featureFlag('nav-enhanced-search') && route.path !== '/')
        && !(featureFlag('nav-hide-search-on-search') && route.path === '/search');
});

const menuTitles = computed(() => {
    return toValue(data)?.navigation?.columns?.map((column) => column.title);
});

const navigationLinks = computed(() => {
    return toValue(data)?.navigation?.columns?.flatMap((column) => column.items);
});

const itemHasSubmenu = (item) => {
    return toValue(item).__typename === 'navigation_links_BlockType';
};

const customNavigationButton = computed(() => {
    const nav = toValue(data)?.navigation;
    if (nav?.navigationButtonText && nav?.navigationButtonLink) {
        return {
            text: nav.navigationButtonText,
            link: uriFromEntryFields(toValue(nav.navigationButtonLink[0]))
        };
    }
    return false;
});

const emit = defineEmits(['menuToggled']);
const menuToggled = ref(false);
watch(() => menuToggled.value, (state) => {
    emit('menuToggled', state);
});

const route = useRoute();
watch(() => route.path, () => {
    menuToggled.value = false;
});

const scrollTop = ref(0);

function getItemLink(item) {
    const internal = toValue(item).internal;
    if (internal?.length) {
        if (internal[0].uri === '__home__') {
            return '/';
        } else {
            return `/${internal[0].uri}`;
        }
    }

    const external = toValue(item).external;
    if (external) {
        return external;
    }

    return '';
}
</script>

<style lang="less" src="./TheNavigation.less"></style>
